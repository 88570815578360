import React, { useState } from 'react';
import { Card, CardContent, Typography, Avatar, Dialog, DialogActions, DialogContent, DialogTitle, Button, Container } from '@mui/material';
import { Buffer } from 'buffer';

const TestimonialCard = ({ testimonial }) => {
  const [openDialog, setOpenDialog] = useState(false); // State to manage dialog open/close
  const [selectedTestimonial, setSelectedTestimonial] = useState(null); // State to store selected testimonial

  // Function to handle opening dialog
  const handleOpenDialog = () => {
    setSelectedTestimonial(testimonial); // Set the selected testimonial for the dialog
    setOpenDialog(true); // Open the dialog
  };

  // Function to handle closing dialog
  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the dialog
  };

  return (
    <Container sx={{alignItems:'center'}}>
      <Card
        className="testimonial-card"
        sx={{
          maxWidth: { xs: '85%', sm: '100%' }, // Fixed width for consistency
          height: 'auto', // Fixed minimum height for equal card height
          display: 'flex', // Ensures that all cards have a flexible layout
          flexDirection: 'column', // Ensures content is vertically aligned
          justifyContent: 'space-between', // Ensures content is evenly spaced
          boxShadow: 3, // Optional: Add shadow for better styling
          borderRadius: 2, // Optional: Rounded corners for the card
          // backgroundColor: 'red',
          marginLeft:{xs:'1rem'},
          cursor: 'pointer', // Change the cursor to pointer to indicate it's clickable
        }}
        onClick={handleOpenDialog} // Open the dialog when card is clicked
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between', // Ensures the content is spaced evenly
            padding: 2, // Padding for the card content
          }}
        >
          {testimonial.photo_format ? (
            <Avatar
              src={`data:image/${testimonial.photo_format || 'jpeg'};base64,${Buffer.from(testimonial.s_photo).toString('base64')}`}
              alt={testimonial.s_name}
              sx={{
                height: '100px',
                width: '100px',
                marginBottom: 2, // Adds space between avatar and text
              }}
            />
          ) : (
            <Avatar
              src=''
              alt={testimonial.s_name}
              sx={{
                height: '100px',
                width: '100px',
                marginBottom: 2, // Adds space between avatar and text
              }}
            />
          )}

          {/* Displaying the student's name */}
          <Typography variant="h6" className="testimonial-name" sx={{ fontFamily: 'Poppins', fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>
            {testimonial.s_name}
          </Typography>
          <Typography variant="subtitle2" className="testimonial-name" sx={{ fontFamily: 'Poppins', fontSize: '1rem', textAlign: 'center' }}>
          Graduated:{testimonial.graduated_college}
          </Typography>

          {/* Displaying the admitted college and program */}
          <Typography variant="subtitle2" className="testimonial-position" sx={{ fontFamily: 'Poppins', fontSize: '1rem', textAlign: 'center', color: '#888' }}>
            {testimonial.admitted_college} - {testimonial.program}
          </Typography>
        </CardContent>
      </Card>

      {/* Dialog for displaying the feedback */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Voice from {selectedTestimonial?.s_name}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins', fontSize: '1rem' }}>
            "{selectedTestimonial?.feedback}"
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Container> 
  );
};

export default TestimonialCard;
